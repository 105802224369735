import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Term`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add phase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add a new object to a list or page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Close`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Close modal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To remove an area of content from the page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove phase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To remove an object without deleting it, commonly used with Add`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      